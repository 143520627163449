import Componenets from './components/components';
import Modules from './modules/modules';
import polyfill from './_config/js/polyfill';
import {
    isInViewport,
    addThisInViewport
} from './utilities/viewport/viewport';

const componets = new Componenets;
const modules = new Modules;

$.fn.isInViewport = isInViewport;

$(function () {
    polyfill();
    componets.onDocumentReady();
    modules.onDocumentReady();
});

$(window).on('scroll', componets.onWindowScroll);
$(window).on('resize scroll', function () {
    if ($('footer').isInViewport()) {
        addThisInViewport();
    }
});

componets.onDocumentClick();

import FeatureWidget from './FeatureWidget/FeatureWidget';

$(function () {
    // zakomentirati da se isključi feature widget
    // const featureWidget = new FeatureWidget();
    // featureWidget.init();
});

// Importing fullpage.js
import 'fullpage.js/vendors/scrolloverflow.min';
import fullpage from 'fullpage.js';

// When using fullPage extensions replace the previous import of fullpage.js for this file
// import fullpage from 'fullpage.js/dist/fullpage.extensions.min';

let homepage = document.getElementById('homepage');
if (null !== homepage) {
    // Initializing it
    var fullPageInstance = new fullpage('#fullPage', {
        anchors: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
        fitToSection: true,
        dragAndMove: true,
        verticalCentered: true,
        sectionSelector: '.scroll-section',
        navigation: true,
        navigationPosition: 'right',
        licenseKey: 'YOUR_KEY_HERE',
        scrollOverflow: true,
        afterLoad: function (origin, destination, direction) {
            if ($('.homepage-careers-section').hasClass('active')) {
                $('.fixed-bottom .custom-container').css('opacity', '0');
            } else {
                $('.fixed-bottom .custom-container').css('opacity', '1');
            }

            if (window.innerWidth < 1367) {
                $('.fp-section.active .image-container .image-default').css('opacity', 1);

                if ($('.fp-section.active .image-container .image-hover').length > 0) {
                    $('.fp-section.active .image-container .image-hover').css('opacity', 1);
                }
            }
        },
        onLeave: function (section, origin, destination, direction) {
            if (window.innerWidth < 1367) {
                $('.fp-section.active .image-container .image-default').removeAttr('style');

                if ($('.fp-section.active .image-container .image-hover').length > 0) {
                    $('.fp-section.active .image-container .image-hover').removeAttr('style');
                }
            }
        }
    });

    fullPageInstance.setRecordHistory(true);

    if (window.location.href.indexOf("#") > -1) {
        fullPageInstance.silentMoveTo(window.location.hash.substr(1));
    }

    window.onhashchange = function () {
        if (window.location.href.indexOf("#") == -1) {
            fullPageInstance.moveTo(1);
        }
    }

    let nav_item_height = $(window).height() / $('.scroll-section').length;
    $('#fp-nav ul li').css('height', nav_item_height + 'px');
    $('#fp-nav ul li a span').css('height', nav_item_height + 'px');

    $('#fp-nav ul li').each(function (i) {
        var prefix = '';
        if (i < 9) {
            prefix = '0';
        }

        $(this).find('a').append('<span class="count">' + prefix + (i + 1) + '</span>');
    });

    $('.scroll-button').on('click', function (e) {
        e.preventDefault();
        fullPageInstance.moveSectionDown();
    });

    $('.inquiry-button').on('click', function (e) {
        e.preventDefault();
        fullPageInstance.moveTo(9);
    });

    $('.navigation-link').on('click', function (e) {
        e.preventDefault();
        fullPageInstance.moveTo($(this).attr('data-index'));
    });
}

function cursorPosition(p) {
    var cursor_horizontal_position = p.pageX;
    var cursor_vertical_position = p.pageY;

    $('.scroll-section').each(function (index) {
        var images = $(this).find('.image.image-default img');

        if (images === 'undefined' || images == '') {
            return false;
        }

        images.each(function (i) {
            var _distance = calculateDistance($(this), cursor_horizontal_position, cursor_vertical_position);

            var opacity = 0.1;
            if (_distance < 800 && _distance > 650) {
                opacity = 0.25;
            } else if (_distance < 650 && _distance > 500) {
                opacity = 0.40;
            } else if (_distance < 500 && _distance > 350) {
                opacity = 0.55;
            } else if (_distance < 350 && _distance > 200) {
                opacity = 0.70;
            } else if (_distance < 200 && _distance > 100) {
                opacity = 0.85;
            } else if (_distance < 200 && _distance > 0) {
                opacity = 1;
            }

            $(this).parent().css('opacity', opacity);
        });
    });
}

function calculateDistance(elem, mouseX, mouseY) {
    return Math.floor(Math.sqrt(Math.pow(mouseX - (elem.offset().left + (elem.width() / 2)), 2) + Math.pow(mouseY - (elem.offset().top + (elem.height() / 2)), 2)));
}

addEventListener('mousemove', cursorPosition, false);

/** NAVIGATION **/
$(function () {
    $('.navbar-toggle, nav').click(function () {
        $('.navbar-toggle').toggleClass('navbar-on');
        $('nav').fadeToggle().removeClass('nav-hide');
    });

    $(document).mouseup(function (e) {
        var container = $('nav, .navbar');

        // If the target of the click isn't the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.hide();
            $('.navbar-toggle').removeClass('navbar-on');
        }
    });

    /*if ($(window).width() <= 1024) {
        var tapped = false
        $('.image-container > a').on('touchstart', function(e) {
            if (!tapped) {
                tapped = setTimeout(function() {
                    e.preventDefault();
                    tapped = null;
                }, 500);
            } else {
                clearTimeout(tapped);
                tapped = null;
                window.location = this.href;
            }
        });
    }*/
});

$(window).on('resize', function () {
    scaleYoutube();
});

function scaleYoutube() {
    if ($(window).width() < 768) {
        $.each($('iframe'), function () {
            var width = $(this).attr('width');
            var height = $(this).attr('height');
            var cont = $('.videos-section .container').width();
            var diff = parseInt(cont) / parseInt(width);
            height = parseInt(height) * parseFloat(diff);

            $(this).css({
                'width': cont,
                'height': height
            });
        });
    }
}

/**
 * Array.prototype.forEach() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Array.prototype.forEach) {
    Array.prototype.forEach = function (callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}

let backButtons = document.querySelectorAll('.back-btn a');
if (backButtons.length > 0) {
    if ('' !== window.location.search) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('back')) {
            console.log(window.history);
            backButtons.forEach(function (button, buttonIndex) {
                button.href = button.href + '#' + searchParams.get('back');
            });
        }
    }
}

$('.back-btn a').on('click', function (e) {
    e.preventDefault();
    window.history.back();
});

/**
 let scrollSections = document.querySelectorAll('.scroll-section');
 if (scrollSections.length > 0) {
    scrollSections.forEach(function (section, sectionIndex) {
        let links = section.querySelectorAll('a');
        links.forEach(function (link, linkIndex) {
            if (linkIndex > 0) {
                link.href = link.href + '?back=' + (sectionIndex + 1);
            }
        });
    });
}
 **/